
//
// accordion.scss
//

.custom-accordion {
    .accordion-list {
        display: flex;
        border-radius: 7px;
        background-color: $gray-300;
        padding: 12px 20px;
        color: $body-color;
        font-weight: $font-weight-semibold;
        align-items: center;
        justify-content: space-between;
        &.collapsed {
            i.accor-plus-icon {
                &:before {
                    content: "\F0415";
                }
            }
        }

        .accor-plus-icon{
            display: inline-block;
            font-size: 16px;
            height: 24px;
            width: 24px;
            line-height: 22px;
            background-color: $card-bg;
            text-align: center;
            border-radius: 50%;
        }
    }

    
    a {

        &.collapsed {
            i.accor-down-icon {
                &:before {
                    content: "\F0140";
                }
            }
        }
    }


    .card-body {
        color: $text-muted;
    }
}

body[data-layout-mode="dark"] {

    // accordion

    .accordion-button{
      border-color: $gray-dark-300;
      color: $gray-dark-600;
      &:not(.collapsed){
          background-color: rgba($primary, 0.2);
          color: $primary;
          box-shadow: inset 0 -1px 0 $gray-dark-200;
      }
    }

    .card-body {
        color: $gray-500;
    }

    .accordion-item{
        border-color: $gray-dark-300;
    }
    
    .accordion-collapse{
        border-color: $gray-dark-300;
    }
}