//
// alerts.scss
//

.alert-top-border {
    background-color: $card-bg;
    border-color: $light;
    border-top: 2px solid $light;
}

.alert-outline{
    background-color: $white;
}

// alert-label-icon

.alert-label-icon {
    position: relative;
    padding-left: 60px;
    border: 0;
  
    .label-icon {
      position: absolute;
      width: 45px;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba($white, 0.1);
      border-right: 1px solid rgba($white, 0.1);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    // label arrow
    &.label-arrow {
      overflow: hidden;
      .label-icon {
        color: $white;
        &:after {
          content: "";
          position: absolute;
          border: 6px solid transparent;
          right: -12px;
        }
      }
    }
  }
  

.alert-light{
    color: $text-muted;
}

@each $color,
$value in $theme-colors {
    .alert-top-border{
        &.alert-#{$color} {
            border-top-color: $value;
            color: $value;
        }
    }

    .alert-outline{
        &.alert-#{$color} {
            border: 1px solid rgba($value, .8);
            color: $value;
        }
    }  

    // alert border left
  .alert-border-left {
    &.alert-#{$color} {
      border-left-color: $value;
    }
  }
    
      // label arrow
  .label-arrow {
    &.alert-#{$color} {
      .label-icon {
        background-color: $value;
        &:after {
          border-left-color: $value !important;
        }
      }
    }
  }
}

// Alert border left example

.alert-border-left {
    border-left: 3px solid;
  }

.alert-top-border, .alert-outline{
    &.alert-light{
        color: $text-muted;
    }
}

body[data-layout-mode="dark"] {
      // Alert
    .alert{
        .btn-close{
            background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
        }
    }
    .alert-top-border{
        background-color: $gray-dark-200;
        border-top: 2px solid $gray-dark-300;
        border-color: $gray-dark-300;
        .btn-close{
            background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
        }
    }
    .alert-top-border{
        background-color: $gray-dark-200;
        border-top: 2px solid $gray-dark-300;
        border-color: $gray-dark-300;
        .btn-close{
            background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
        }
    }

    .alert-outline{
        background-color: $gray-dark-200;
        .btn-close{
            background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
        }
        &.alert-light{
            color: $light;
        }
    }

    @each $color,
    $value in $theme-colors {
        .alert-top-border{
            &.alert-#{$color} {
                border-top-color: $value;
                color: $value;
            }
        }
    }

    .alert-top-border{
        &.alert-dark{
            border-top-color: $gray-dark-300;
            border-color: $gray-dark-300;
            color: $light;
        }
    }

    .alert-outline{
        &.alert-dark{
            border-color: $gray-dark-300;
            color: $light;
        }
    }
}