.h2, h2 {
    font-size: 1.2rem !important;
}

.h1, h1 {
    font-size: 1.7rem !important;
    margin-top: 50px !important;
}
h1:first-of-type {
    margin-top: 0 !important;
}