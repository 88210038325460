body {
    overflow: hidden !important;
}

.card {
    margin-bottom: 0 !important;
    margin-top: 20px !important;
}
.chatCard {
    border-radius: 15px;
    margin: 0px !important;
}
html {
 background: white;
}
