body {
    overflow: hidden !important;
}

.chat-bubble .card {
    margin: 0 !important;
}
html{
    background: white;

}
.chat-bubble #chat-message-wrapper{
    height: 430px;
}
