// 
// _widgets.scss
// 

.main-chart {
    .chart-border-left {
        border-left: 1.4px solid $border-color;
        padding: 2px 20px;

        @media (max-width: 575.98px) {
            border: 0;
        }

        &:last-child {
            margin-right: 0px;
        }
    }
}


// index chart
@media (max-width: 350px) {
    #sales-category{
        height: 240px ;
    }
}

.widget-chat{
    height: 300px !important;
}

.widget-chat-list{
    height: 373px !important;
}

// map widget
.map-widget{
    @media (min-width: 992px) {
        border-right: 1px solid $border-color;
    }
}


/* Activity */


.activity-feed {
    list-style: none;

    .feed-item {
        position: relative;
        padding-bottom: 24px;
        padding-left: 20px;
        border-left: 2px solid $light;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: -9px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 2px solid $primary;
            background-color: $card-bg;
        }

        &:first-child {
            border-color: $primary;
            &:after {
                background-color: $primary;
            }
        }
       

        &:last-child {
            border-color: transparent;
        }
    }
}


// for bubble effect
.bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    overflow: hidden;
    z-index: 1;
    li {
        position: absolute;
        list-style: none;
        display: block;
        top: -50px;
        color: $white;
        animation: square 20s infinite;
        transition-timing-function: linear;
        &:nth-child(1) {
            left: 5%;
            top: -50px;
        }
        &:nth-child(2) {
            left: 15%;
            top: -100px;
            animation-delay: 2s;
        }
        &:nth-child(3) {
            left: 25%;
            top: -200px;
            animation-delay: 4s;
        }
        &:nth-child(4) {
            left: 50%;
            top: -100px;
            animation-duration: 22s;
        }
        &:nth-child(5) {
            left: 80%;
            top: -250px;
        }
        &:nth-child(6) {
            left: 70%;
            top: -350px;
            animation-delay: 3s;
        }
        &:nth-child(7) {
            left: 80%;
            top: -50px;
            animation-delay: 7s;
        }
        &:nth-child(8) {
            left: 55%;
            top: 50px;
            animation-delay: 15s;
            animation-duration: 40s;
        }
        &:nth-child(9) {
            left: 85%;
            top: 50px;
            animation-delay: 2s;
            animation-duration: 40s;
        }
        &:nth-child(10) {
            left: 90%;
            top: -100px;
            animation-delay: 11s;
        }
    }
}


.main-icon{
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    right: 0;
    i{
        font-size: 24px;
        color: $white;
    }
}

.main-wid{
    position: relative;
    z-index: 2;
}

@keyframes square {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(350px) rotate(600deg);
    }
}

body[data-layout-mode="dark"] {

// activity
    
.activity-feed {
    .feed-item {
        border-left: 2px solid lighten($gray-dark-200, 4%);
        &:after {
            background-color: $gray-dark-200;
        }
        &:first-child {
            border-color: $primary;
            &:after {
                background-color: $primary;
            }
        }
    }
}

.main-chart{
    .chart-border-left{
        border-left: 1.4px solid $gray-dark-300;
    }
}

// map widget
.map-widget{
    @media (min-width: 992px) {
        border-right: 1px solid $gray-dark-300;
    }
}

.best-product-title{
  color: $dark;
}

}


.best-product{
    background-image: url("../images/product/best-img.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

[dir="rtl"]{
    .best-product{
        background-image: url("../images/product/best-img-rtl.jpg");
    }
  }